import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./LandingPage.module.css";
import { LiaGripLinesVerticalSolid } from "react-icons/lia";
import axios from "axios";
import floatingsageLogo from "../../assets/High-Resolution-Color-Logo-on-Transparent-Background - cropped.png";

// Dummy chatbot data
const chatbotData = [
    { id: 1, name: "Product Documentation Chatbot" },
    { id: 2, name: "Marketing Chatbot" },
    { id: 3, name: "Database Chatbot" }
];

const LandingPage: React.FC = () => {
    const navigate = useNavigate();

    // Logout handler
    const handleLogout = async () => {
        try {
            const email = localStorage.getItem("userEmail");
            if (!email) {
                console.error("Email is missing. Cannot log out.");
                return;
            }

            // Call backend logout API
            const response = await axios.post(
                "/api/logout",
                { email },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`
                    }
                }
            );

            if (response.status === 200) {
                localStorage.clear(); // Clear session details
                navigate("/"); // Redirect to sign-in page
            }
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    // Handle navigation with token validation
    const navigateWithValidation = async (route: string) => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await axios.get("/validate-token", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                navigate(route);
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                // Redirect to LoggedOutPage if the session has expired
                localStorage.clear();
                navigate("/logged-out");
            }
        }
    };

    return (
        <div className={styles.landingPage}>
            {/* Header */}
            <header className={styles.header}>
                <div className={styles.headerContainer}>
                    <Link reloadDocument to="/" className={styles.headerTitleContainer}>
                        <img src={floatingsageLogo} alt="Floating Sage logo" className={styles.headerLogo} />
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li className={styles.headerNavItem}>
                                <button onClick={handleLogout} className={styles.button}>
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            {/* Main Content */}
            <h1 className={styles.title}>Welcome to Your Chatbot Dashboard</h1>
            <div className={styles.chatbotsContainer}>
                {chatbotData.map(chatbot => (
                    <div key={chatbot.id} className={styles.chatbotRow}>
                        <span className={styles.chatbotName}>{chatbot.name}</span>
                        <div className={styles.linkContainer}>
                            <a href="#" onClick={() => navigateWithValidation(`/lander`)} className={styles.link}>
                                Chat Bot
                            </a>
                            <LiaGripLinesVerticalSolid className={styles.dividerIcon} />
                            <a href="#" onClick={() => navigateWithValidation(`/data-upload`)} className={styles.link}>
                                Upload Data
                            </a>
                            <LiaGripLinesVerticalSolid className={styles.dividerIcon} />
                            <Link to="/page-not-found" className={styles.link}>
                                Usage Reports
                            </Link>
                            <LiaGripLinesVerticalSolid className={styles.dividerIcon} />
                            <Link to="/page-not-found" className={styles.link}>
                                Support Tickets
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LandingPage;
