import { useRef, useState, useEffect } from "react";
import { Checkbox, Panel, DefaultButton, SpinButton, ChoiceGroup, IChoiceGroupOption, Label } from "@fluentui/react";
import { Delete24Regular, Person24Regular, Settings24Regular } from "@fluentui/react-icons";
import logo from "../../assets/FloatingSage.gif";
import styles from "./Chat.module.css";
import { Modal } from "@fluentui/react";
import axios from "axios";

import {
    chatApi,
    clearChatSession,
    ChatResponse,
    ChatRequest,
    UserProfile,
    ApproachType,
    ChatError,
    ChatResponseError,
    UserQuestion,
    SearchSettings,
    rating,
    ratingRequest
} from "../../api";
import { Answer, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { TopBarButton } from "../../components/TopBarButton";
import { ErrorToast } from "../../components/ErrorToast";

type Props = {
    users: UserProfile[];
    searchSettings: SearchSettings;
};

const Chat = ({ users, searchSettings }: Props) => {
    const [isUserPanelOpen, setIsUserPanelOpen] = useState(false);
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(false); // Default set to unchecked
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [useVectorSearch, setUseVectorSearch] = useState<boolean>(searchSettings.vectorization_enabled);

    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [conversationID, setConversationID] = useState<string>();

    const [selectedUser, setSelectedUser] = useState<UserProfile>();

    const [lastQuestion, setLastQuestion] = useState<UserQuestion | undefined>(undefined);
    const [isAnswerLoading, setIsAnswerLoading] = useState<boolean>(false);
    const [answerError, setAnswerError] = useState<ChatError | undefined>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [dialog, setDialog] = useState<[user: string, response: ChatResponse][]>([]);

    const [isLikedlist, setisLikedlist] = useState<number[]>([]);
    const [isDislikedlist, setisDislikedlist] = useState<number[]>([]);

    const [feedbackVisible, setFeedbackVisible] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");
    const [feedbackIndex, setFeedbackIndex] = useState<number | null>(null);
    const [feedbackRequired, setFeedbackRequired] = useState(false);
    const [feedbackNote, setFeedbackNote] = useState("");

    // New states for Industry 1, Surface 1, and Medical 1
    const [dataSources, setDataSources] = useState<string[]>([]); // Store data sources fetched from backend
    const [selectedDataSources, setSelectedDataSources] = useState<{ [key: string]: boolean }>({}); // Store selected state for each data source

    const [isModalOpen, setIsModalOpen] = useState<{ isOpen: boolean; tab?: AnalysisPanelTabs; index?: number }>({ isOpen: false });
    const closeModal = () => setIsModalOpen({ isOpen: false });

    useEffect(() => {
        const fetchDataSources = async () => {
            try {
                const token = localStorage.getItem("authToken"); // Retrieve token from local storage
                const response = await axios.get("/organization-data-sources", {
                    headers: {
                        Authorization: `Bearer ${token}` // Include token for authorization
                    }
                });

                const sources = response.data?.data_sources || [];
                setDataSources(sources);

                // Initialize the selected state for checkboxes
                const initialSelectedState: { [key: string]: boolean } = {};
                sources.forEach((source: string) => {
                    initialSelectedState[source] = true; // Default all checkboxes to checked
                });
                setSelectedDataSources(initialSelectedState);
            } catch (error) {
                console.error("Error fetching data sources:", error);
            }
        };

        fetchDataSources();
    }, []);

    useEffect(() => {
        if (users.length > 0) {
            setSelectedUser(users[0]);
        }

        setConversationID(crypto.randomUUID());
    }, []);

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isAnswerLoading]);

    useEffect(() => {
        if (lastQuestion) {
            getQuestionAnswer(lastQuestion.question, lastQuestion.classificationOverride);
        }
    }, [lastQuestion]);

    const updateLastQuestion = (question?: string, classificationOverride: ApproachType | undefined = undefined) => {
        if (question) {
            setLastQuestion({ question, classificationOverride });
        } else {
            setLastQuestion(undefined);
        }
    };

    const getQuestionAnswer = async (question: string, classificationOverride: ApproachType | undefined = undefined) => {
        answerError && setAnswerError(undefined);
        setIsAnswerLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const request: ChatRequest = {
                userID: selectedUser ? selectedUser.user_id : "",
                conversationID: conversationID ? conversationID : "",
                dialogID: crypto.randomUUID(),
                dialog: question,
                overrides: {
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker, // Keeps unchecked value
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions,
                    vectorSearch: useVectorSearch, // Hidden but holds value
                    classificationOverride,
                    pdfDataSources: Object.entries(selectedDataSources)
                        .filter(([_, isSelected]) => isSelected) // Include only selected data sources
                        .map(([source]) => source) // Map selected sources to an array
                }
            };
            const result = await chatApi(request);
            setDialog([...dialog, [question, result]]);
        } catch (e) {
            if (e instanceof ChatResponseError) {
                setAnswerError({ message: e.message, retryable: e.retryable });
            } else if (e instanceof Error) {
                setAnswerError({ message: e.message, retryable: true });
            }
            console.log(`Error getting answer from /chat API: ${e}`);
        } finally {
            setIsAnswerLoading(false);
        }
    };

    const retryWithOverride = (classificationOverride: ApproachType) => {
        setIsAnswerLoading(true);
        const lastUserQuestion = dialog[dialog.length - 1][0];
        setActiveAnalysisPanelTab(undefined);
        setDialog(dialog.slice(0, -1)); // Don't show previous dialog in the chat
        updateLastQuestion(lastUserQuestion, classificationOverride);
    };

    const clearChat = async () => {
        updateLastQuestion(undefined);
        answerError && setAnswerError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setDialog([]);
        try {
            if (selectedUser && conversationID) {
                await clearChatSession(selectedUser.user_id, conversationID);
            }
        } catch (e) {
            console.log(`Failed to clear chat session in server: ${e}`);
        }
    };

    const onUserSelectionChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        if (option) {
            const profile = users.find(user => user.user_id == option.key);
            if (profile) {
                updateLastQuestion(undefined);
                answerError && setAnswerError(undefined);
                setActiveCitation(undefined);
                setActiveAnalysisPanelTab(undefined);
                setDialog([]);
                setConversationID(crypto.randomUUID());
                setSelectedUser(profile);
            }
        }
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onUseVectorSearchChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseVectorSearch(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        updateLastQuestion(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
            setActiveCitation(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
            setActiveCitation(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);

            if (selectedAnswer !== index) {
                setActiveCitation(undefined);
            }
        }

        setSelectedAnswer(index);
    };

    const undoLike = async (index: number) => {
        console.log("-----Function undoLike called for index: " + index + "-----");
        const request: ratingRequest = {
            user_id: selectedUser ? selectedUser.user_id : "",
            conversation_id: conversationID ? conversationID : "",
            index_no: index,
            rating: 0,
            feedback: "" // Pass an empty string for feedback
        };
        const result = await rating(request);
        console.log("-----printing value of isResponseLiked-----");
        console.log("-----printing value of isResponseDisliked-----");
        setisLikedlist(isLikedlist.filter(element => element !== index));
    };

    const undoDislike = async (index: number) => {
        console.log("-----Function undoDislike called for index: " + index + "-----");
        const request: ratingRequest = {
            user_id: selectedUser ? selectedUser.user_id : "",
            conversation_id: conversationID ? conversationID : "",
            index_no: index,
            rating: 0,
            feedback: "" // Pass an empty string for feedback
        };
        const result = await rating(request);
        console.log("-----printing value of isResponseLiked-----");
        console.log("-----printing value of isResponseDisliked-----");
        setisDislikedlist(isDislikedlist.filter(element => element !== index));
    };

    const sendLikeRating = (index: number) => {
        console.log("------Function sendLikeRating called for index: " + index + "-------");

        // Open feedback box with feedback as optional for likes
        setFeedbackVisible(true);
        setFeedbackRequired(false); // Feedback is optional for likes
        setFeedbackIndex(index);
        setFeedbackText("");
        setFeedbackNote(""); // Clear any existing note
        setisLikedlist([...isLikedlist, index]);

        // Remove from dislike list if previously disliked
        if (isDislikedlist.includes(index)) {
            setisDislikedlist(isDislikedlist.filter(element => element !== index));
        }
    };

    const sendDislikeRating = (index: number) => {
        console.log("------Function sendDislikeRating called for index: " + index + "-------");

        // Open feedback box with feedback as mandatory for dislikes
        setFeedbackVisible(true);
        setFeedbackRequired(true); // Feedback is mandatory for dislikes
        setFeedbackNote("Feedback is mandatory to submit a dislike rating."); // Updated note text
        setFeedbackIndex(index);
        setFeedbackText("");
        setisDislikedlist([...isDislikedlist, index]);

        // Remove from like list if previously liked
        if (isLikedlist.includes(index)) {
            setisLikedlist(isLikedlist.filter(element => element !== index));
        }
    };

    const handleFeedbackSubmit = async () => {
        if (feedbackRequired && feedbackText.trim() === "") {
            // Set the note to red if feedback is missing
            setFeedbackNote("Feedback is mandatory to submit a dislike rating.");
            return;
        }

        if (feedbackIndex !== null) {
            const request = {
                user_id: selectedUser ? selectedUser.user_id : "",
                conversation_id: conversationID ? conversationID : "",
                index_no: feedbackIndex,
                rating: isLikedlist.includes(feedbackIndex) ? 1 : -1,
                feedback: feedbackText
            };

            await rating(request);
            setFeedbackVisible(false); // Close the feedback box
            setFeedbackText(""); // Clear the feedback text
            setFeedbackIndex(null); // Reset feedback index
            setFeedbackNote(""); // Clear feedback note
        }
    };

    const handleCloseFeedback = () => {
        setFeedbackVisible(false);
        setFeedbackText("");
        setFeedbackIndex(null);
        setFeedbackNote("");
        setisDislikedlist(isDislikedlist.filter(item => item !== feedbackIndex)); // Ensure dislike is not set
    };

    // Function to handle checkbox changes
    const dataSourceChangeFn = (setFunction: React.Dispatch<React.SetStateAction<boolean>>, checked?: boolean) => {
        setFunction(!!checked);
    };

    // Function to handle weight changes
    const handleWeightChange = (setWeightFn: React.Dispatch<React.SetStateAction<number>>, newValue?: string) => {
        setWeightFn(parseInt(newValue || "0"));
    };

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <TopBarButton className={styles.commandButton} icon={<Delete24Regular />} onClick={clearChat} disabled={!lastQuestion || isAnswerLoading} />
                <TopBarButton className={styles.commandButton} icon={<Person24Regular />} onClick={() => setIsUserPanelOpen(!isUserPanelOpen)} />
                <TopBarButton className={styles.commandButton} icon={<Settings24Regular />} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestion ? (
                        <div className={styles.chatEmptyState}>
                            <img className={styles.chatLogo} src={logo} alt="Chat logo" />
                            <h1 className={styles.chatEmptyStateTitle}>Chat with GPT Sidekick</h1>
                            <h2 className={styles.chatEmptyStateSubtitle}>Ask me anything from Harrison - Textbook of Medicine</h2>
                            <ExampleList onExampleClicked={onExampleClicked} examples={selectedUser?.sample_questions ? selectedUser.sample_questions : []} />
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {dialog.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            chatResponse={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            sendLikeRating={() => sendLikeRating(index)}
                                            sendDislikeRating={() => sendDislikeRating(index)}
                                            undoLike={() => undoLike(index)}
                                            undoDislike={() => undoDislike(index)}
                                            isLiked={isLikedlist.includes(index)}
                                            isDisliked={isDislikedlist.includes(index)}
                                            onThoughtProcessClicked={() => setIsModalOpen({ isOpen: true, tab: AnalysisPanelTabs.ThoughtProcessTab, index })}
                                            onSupportingContentClicked={() =>
                                                setIsModalOpen({ isOpen: true, tab: AnalysisPanelTabs.SupportingContentTab, index })
                                            }
                                            onFollowupQuestionClicked={q => updateLastQuestion(q)}
                                            showFollowupQuestions={useSuggestFollowupQuestions && dialog.length - 1 === index}
                                            onRetryClicked={() => {
                                                if (answer[1].suggested_classification) {
                                                    retryWithOverride(answer[1].suggested_classification as ApproachType);
                                                }
                                            }}
                                            retryable={index == dialog.length - 1 && !!answer[1].show_retry && !isAnswerLoading && !answerError}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isAnswerLoading && (
                                <>
                                    <UserChatMessage message={lastQuestion.question} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {answerError ? (
                                <>
                                    <UserChatMessage message={lastQuestion.question} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <ErrorToast
                                            message={answerError.message}
                                            retryable={answerError.retryable}
                                            onRetry={() => updateLastQuestion(lastQuestion.question)}
                                        />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    {/* Feedback Text Box */}
                    {feedbackVisible && (
                        <div className={styles.feedbackBox}>
                            <textarea
                                value={feedbackText}
                                onChange={e => setFeedbackText(e.target.value)}
                                placeholder="Enter your feedback"
                                className={styles.feedbackTextarea}
                            />
                            {feedbackRequired && (
                                <div className={`${styles.feedbackNote} ${feedbackText.trim() === "" ? styles.blink : ""}`}>
                                    Feedback is mandatory to submit a dislike rating.
                                </div>
                            )}
                            <button onClick={handleFeedbackSubmit} className={styles.submitButton}>
                                Submit Feedback
                            </button>
                            <button onClick={handleCloseFeedback} className={styles.closeButton}>
                                ✖
                            </button>
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Type a new question"
                            disabled={isAnswerLoading}
                            onSend={question => updateLastQuestion(question)}
                        />
                    </div>
                </div>

                {dialog.length > 0 && activeAnalysisPanelTab && (
                    <div className={styles.analysisPanelContainer}>
                        <AnalysisPanel
                            className={styles.chatAnalysisPanel}
                            activeCitation={activeCitation}
                            // onActiveTabChanged={tab => onToggleTab(tab, selectedAnswer)}
                            citationHeight="810px"
                            chatResponse={dialog[selectedAnswer][1]}
                            activeTab={activeAnalysisPanelTab}
                        />
                    </div>
                )}

                <Panel
                    headerText="User profile"
                    isOpen={isUserPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsUserPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsUserPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <ChoiceGroup
                        className={styles.chatSettingsSeparator}
                        label="User Profile"
                        options={users.map(user => ({ key: user.user_id, text: user.user_name }))}
                        onChange={onUserSelectionChange}
                        defaultSelectedKey={selectedUser?.user_id}
                    />

                    <Label className={styles.chatSettingsSeparator}>{selectedUser?.description}</Label>
                </Panel>
                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={3}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    {/* "Use semantic ranker for retrieval" removed from display but logic remains */}
                    {/* "Use vector search for retrieval" removed */}
                    {/* "Use query-contextual summaries instead of whole documents" removed */}
                    {/* "Exclude category" removed */}
                    {dataSources.map(source => (
                        <div key={source} className={styles.dataSourceRow}>
                            <Checkbox
                                label={source} // Use the data source name as the label
                                checked={selectedDataSources[source] || false} // Check state dynamically
                                onChange={(ev, checked) => setSelectedDataSources(prev => ({ ...prev, [source]: !!checked }))} // Update state
                                className={styles.dataSourceLabel}
                            />
                        </div>
                    ))}
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
