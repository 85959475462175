import React from "react";
import styles from "./UnderConstructionPage.module.css";

const UnderConstructionPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.messageBox}>
                <h1 className={styles.heading}>We're Building Something Amazing</h1>
                <p className={styles.subText}>Our team is hard at work creating this page. Check back soon to experience our latest updates and innovations!</p>
                <div className={styles.loader}></div>
            </div>
        </div>
    );
};

export default UnderConstructionPage;
