import { Text } from "@fluentui/react";
import { FluentIconsProps } from "@fluentui/react-icons";

import styles from "./TopBarButton.module.css";
import { ReactElement } from "react";

interface Props {
    className?: string;
    icon: ReactElement<FluentIconsProps>;
    onClick: () => void;
    disabled?: boolean;
}

export const TopBarButton = ({ className, icon, disabled, onClick }: Props) => {
    return (
        <div
            className={`${styles.container} ${className ?? ""} ${disabled && styles.disabled}`}
            onClick={!disabled ? onClick : undefined} // Disable click when button is disabled
            role="button" // Accessibility improvement
            tabIndex={disabled ? -1 : 0} // Allow focus only when enabled
            aria-disabled={disabled} // Accessibility for disabled state
        >
            {icon}
        </div>
    );
};
