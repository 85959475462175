// Importing React and necessary components
import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import styles from "./DataUpload.module.css";
import { FaFileUpload, FaSort } from "react-icons/fa";

// Backend endpoint to fetch indexer details
const BACKEND_URL = "/api/indexer-details"; // Adjust the URL according to your backend setup
// const BACKEND_URL = "https://floatingsage.com/api/indexer-details";

// Function to determine data source based on indexer name prefix
const getDataSourceName = (indexerName: string) => {
    if (indexerName.startsWith("salesforce")) return "Salesforce";
    if (indexerName.startsWith("sharepoint")) return "SharePoint";
    if (indexerName.startsWith("google_storage")) return "Google Storage";
    if (indexerName.startsWith("aws")) return "AWS";
    if (indexerName.startsWith("azure")) return "Azure";
    if (indexerName.startsWith("request-admin")) return "Request Admin";
    if (indexerName.startsWith("local")) return "Local";
    return "Unknown";
};

const DataUpload = () => {
    const [indexerData, setIndexerData] = useState<any[]>([]); // To hold indexer data fetched from backend
    const [modalDataSource, setModalDataSource] = useState<any | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedDataSourceInModal, setSelectedDataSourceInModal] = useState<string>("");
    const [selectedChunkingStrategy, setSelectedChunkingStrategy] = useState<string>("onePage");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [useCase, setUseCase] = useState<string>("");
    const [fileType, setFileType] = useState<string>("");
    const [fileCount, setFileCount] = useState<number | string>("");
    const [fileSize, setFileSize] = useState<string>("");
    const [instanceUrl, setInstanceUrl] = useState<string>(""); // For Salesforce, etc.
    const [clientId, setClientId] = useState<string>(""); // For Salesforce, etc.
    const [clientSecret, setClientSecret] = useState<string>(""); // For Salesforce, etc.
    const [username, setUsername] = useState<string>(""); // For Salesforce, etc.
    const [password, setPassword] = useState<string>(""); // For Salesforce, etc.
    const [siteUrl, setSiteUrl] = useState<string>(""); // For SharePoint, etc.
    const [bucketName, setBucketName] = useState<string>(""); // For Google Storage/AWS/Azure
    const [region, setRegion] = useState<string>(""); // For AWS
    const [storageAccountName, setStorageAccountName] = useState<string>(""); // For Azure
    const [accessKey, setAccessKey] = useState<string>(""); // For Azure/AWS
    const [containerName, setContainerName] = useState<string>(""); // For Azure
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [uploadStatus, setUploadStatus] = useState<"loading" | "success" | "error" | "">("");
    const [errorCode, setErrorCode] = useState<number | null>(null);

    // Fetch the indexer details from the backend
    useEffect(() => {
        const fetchIndexerDetails = async () => {
            try {
                const response = await fetch(BACKEND_URL);
                const data = await response.json();

                // Add the data_source_name based on the indexer name
                const enrichedData = data.map((indexer: any) => ({
                    ...indexer,
                    data_source_name: getDataSourceName(indexer.indexer_name)
                }));

                setIndexerData(enrichedData);
                setIsLoading(false); // Stop loading once data is fetched
            } catch (error) {
                console.error("Error fetching indexer details:", error);
                setIsLoading(false); // Stop loading even if there's an error
            }
        };

        fetchIndexerDetails();
    }, []);

    // Handler for specific "Upload Data" buttons
    const handleSpecificUpload = (dataSource: any) => {
        setModalDataSource(dataSource);
        setShowModal(true);
    };

    // Handler for file selection specifically for Local upload (with validation for PDF and size)
    const handleFileLocalUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;

        if (file) {
            const fileType = file.type;
            const fileSize = file.size / 1024 / 1024; // Convert size to MB

            // Check if the file is a PDF and below 5 MB
            if (fileType !== "application/pdf") {
                alert("Only PDF files are allowed.");
                setSelectedFile(null); // Reset file input
            } else if (fileSize > 5) {
                alert("File size must be less than 5 MB.");
                setSelectedFile(null); // Reset file input
            } else {
                setSelectedFile(file); // Valid file selected
            }
        }
    };

    const getErrorMessage = (statusCode: number) => {
        switch (statusCode) {
            case 401:
                return "No file part in the request. Please try again.";
            case 402:
                return "No file selected. Please select a file and try again.";
            case 403:
                return "Invalid file type. Only PDF files are allowed.";
            case 404:
                return "Error processing the file. Please try again later.";
            case 405:
                return "Error uploading the file to Azure. Please try again later.";
            case 406:
                return "Error triggering the indexer. Please try again later.";
            case 407:
                return "Indexer failed to process the file. Please try again later.";
            case 408:
                return "Indexer did not complete within the allowed time. Please try again later.";
            case 500:
                return "An unexpected error occurred. Please try again later.";
            default:
                return "Unknown error occurred. Please try again.";
        }
    };

    // Handler for global "Upload New Data" button
    const handleGlobalUpload = () => {
        setModalDataSource(null);
        setSelectedDataSourceInModal("");
        setShowModal(true);
    };

    // Handler to close the modal
    const closeModal = () => {
        setShowModal(false);
        setModalDataSource(null);
        setSelectedDataSourceInModal("");
        setSelectedChunkingStrategy("onePage");
        setSelectedFile(null); // Reset selected file on modal close
        setUseCase("");
        setFileType("");
        setFileCount("");
        setFileSize("");
        setInstanceUrl(""); // Reset Salesforce/SharePoint inputs
        setClientId("");
        setClientSecret("");
        setUsername("");
        setPassword("");
        setSiteUrl("");
        setBucketName("");
        setRegion("");
        setStorageAccountName("");
        setAccessKey("");
        setContainerName("");
    };

    // Handler for file selection
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    // Handler for form submission
    const handleSubmit = async () => {
        // Add `async` here
        // Consolidating input handling for all data sources
        switch (modalDataSource?.data_source_name || selectedDataSourceInModal) {
            case "Request Admin":
                console.log(`Request Admin form submitted with the following details:
                Use Case: ${useCase}
                File Type: ${fileType}
                File Count: ${fileCount}
                File Size: ${fileSize}
                Chunking Strategy: ${selectedChunkingStrategy}`);
                break;
            case "Salesforce":
                console.log(`Salesforce form submitted with:
                Instance URL: ${instanceUrl}
                Client ID: ${clientId}
                Client Secret: ${clientSecret}
                Username: ${username}
                Password: ${password}`);
                break;
            case "SharePoint":
                console.log(`SharePoint form submitted with:
                Site URL: ${siteUrl}
                Username: ${username}
                Password: ${password}`);
                break;
            case "Google Storage":
                console.log(`Google Storage form submitted with:
                Bucket Name: ${bucketName}
                Selected File: ${selectedFile}`);
                break;
            case "AWS":
                console.log(`AWS form submitted with:
                Bucket Name: ${bucketName}
                Region: ${region}
                Access Key: ${accessKey}
                Selected File: ${selectedFile}`);
                break;
            case "Azure":
                console.log(`Azure form submitted with:
                Storage Account Name: ${storageAccountName}
                Access Key: ${accessKey}
                Container Name: ${containerName}
                Selected File: ${selectedFile}`);
                break;
            case "Local": // Handle Local upload specifically
                if (selectedFile) {
                    const formData = new FormData();
                    formData.append("file", selectedFile); // Append the selected file to form data

                    setIsLoading(true); // Show loading animation
                    setUploadStatus("loading");

                    try {
                        const response = await fetch("/api/upload", {
                            method: "POST",
                            body: formData
                        });

                        if (response.ok) {
                            setUploadStatus("success");
                            setErrorCode(null);
                        } else {
                            const status = response.status;
                            setErrorCode(status);
                            alert(getErrorMessage(status));
                            setUploadStatus("error");
                        }
                    } catch (error) {
                        console.error("Error during file upload:", error);
                        alert("An unexpected error occurred while uploading the file.");
                        setUploadStatus("error");
                    } finally {
                        setIsLoading(false); // Keep the popup open and let user manually close it
                    }
                } else {
                    alert("No valid file selected for upload.");
                }

                break;

            default:
                console.log(`No specific data source selected.`);
        }
        closeModal();
    };

    // Rendering form fields based on the selected or fixed data source
    const renderDataSourceForm = (dataSource: string) => {
        switch (dataSource) {
            case "Request Admin":
                return (
                    <>
                        <label>
                            What is your use case?: <input type="text" value={useCase} onChange={e => setUseCase(e.target.value)} />
                        </label>
                        <label>
                            What type of files do you have?: <input type="text" value={fileType} onChange={e => setFileType(e.target.value)} />
                        </label>
                        <label>
                            How many files do you have?: <input type="number" value={fileCount} onChange={e => setFileCount(e.target.value)} />
                        </label>
                        <label>
                            What is the size of files?: <input type="text" value={fileSize} onChange={e => setFileSize(e.target.value)} />
                        </label>
                    </>
                );
            case "Salesforce":
                return (
                    <>
                        <label>
                            Instance URL: <input type="text" value={instanceUrl} onChange={e => setInstanceUrl(e.target.value)} />
                        </label>
                        <label>
                            Client ID: <input type="text" value={clientId} onChange={e => setClientId(e.target.value)} />
                        </label>
                        <label>
                            Client Secret: <input type="text" value={clientSecret} onChange={e => setClientSecret(e.target.value)} />
                        </label>
                        <label>
                            Username: <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
                        </label>
                        <label>
                            Password: <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </label>
                    </>
                );
            case "SharePoint":
                return (
                    <>
                        <label>
                            Site URL: <input type="text" value={siteUrl} onChange={e => setSiteUrl(e.target.value)} />
                        </label>
                        <label>
                            Username: <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
                        </label>
                        <label>
                            Password: <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </label>
                    </>
                );
            case "Google Storage":
                return (
                    <>
                        <label>
                            Bucket Name: <input type="text" value={bucketName} onChange={e => setBucketName(e.target.value)} />
                        </label>
                        <label>
                            Select File: <input type="file" onChange={handleFileUpload} />
                        </label>
                    </>
                );
            case "AWS":
                return (
                    <>
                        <label>
                            Bucket Name: <input type="text" value={bucketName} onChange={e => setBucketName(e.target.value)} />
                        </label>
                        <label>
                            Region: <input type="text" value={region} onChange={e => setRegion(e.target.value)} />
                        </label>
                        <label>
                            Access Key: <input type="text" value={accessKey} onChange={e => setAccessKey(e.target.value)} />
                        </label>
                        <label>
                            Select File: <input type="file" onChange={handleFileUpload} />
                        </label>
                    </>
                );
            case "Azure":
                return (
                    <>
                        <label>
                            Storage Account Name: <input type="text" value={storageAccountName} onChange={e => setStorageAccountName(e.target.value)} />
                        </label>
                        <label>
                            Access Key: <input type="text" value={accessKey} onChange={e => setAccessKey(e.target.value)} />
                        </label>
                        <label>
                            Container Name: <input type="text" value={containerName} onChange={e => setContainerName(e.target.value)} />
                        </label>
                        <label>
                            Select File: <input type="file" onChange={handleFileUpload} />
                        </label>
                    </>
                );
            case "Local": // New case for Local Upload
                return (
                    <>
                        <label>
                            Select File: <input type="file" onChange={handleFileLocalUpload} />
                        </label>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles.dataUploadPage}>
            {(isLoading || uploadStatus) && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingPopup}>
                        {uploadStatus !== "loading" && ( // Show close button only if not loading
                            <button
                                className={styles.closeButton}
                                onClick={() => {
                                    setIsLoading(false);
                                    setUploadStatus("");
                                }}
                            >
                                <FaTimes className={styles.closeIcon} /> {/* Close Icon */}
                            </button>
                        )}
                        {isLoading && uploadStatus === "" ? (
                            <>
                                <div className={styles.spinner}></div>
                                <p>Please wait, table is populating...</p>
                            </>
                        ) : uploadStatus === "loading" ? (
                            <>
                                <div className={styles.spinner}></div>
                                <p>Uploading your file to the knowledge base...</p>
                            </>
                        ) : uploadStatus === "success" ? (
                            <>
                                <FaCheck className={`${styles.statusIcon} ${styles.success}`} /> {/* Green check */}
                                <p>File uploaded successfully!</p>
                            </>
                        ) : uploadStatus === "error" ? (
                            <>
                                <FaTimes className={`${styles.statusIcon} ${styles.error}`} /> {/* Red cross */}
                                <p>{errorCode !== null ? getErrorMessage(errorCode) : "An unknown error occurred. Please try again."}</p>
                            </>
                        ) : null}
                    </div>
                </div>
            )}

            <div className={styles.dataSourcesContainer}>
                <h1 className={styles.title}>Data Upload Platform</h1>

                {isLoading && uploadStatus === "" ? (
                    // Show the loading popup for table loading
                    <div className={styles.loadingOverlay}>
                        <div className={styles.loadingPopup}>
                            <div className={styles.spinner}></div>
                            <p>Please wait, table is populating...</p>
                        </div>
                    </div>
                ) : (
                    <table className={styles.dataSourcesTable}>
                        <thead>
                            <tr>
                                <th>
                                    Data Source <FaSort />
                                </th>
                                <th>
                                    Indexer Name <FaSort />
                                </th>
                                <th>
                                    Documents Succeeded <FaSort />
                                </th>
                                <th>
                                    Last Run Time <FaSort />
                                </th>
                                <th>
                                    Index Name <FaSort />
                                </th>
                                <th>
                                    Total Documents in Index <FaSort />
                                </th>
                                <th>Upload Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {indexerData.map((indexer: any) => (
                                <tr key={indexer.indexer_name}>
                                    <td>{indexer.data_source_name}</td>
                                    <td>{indexer.indexer_name}</td>
                                    <td>{indexer.docs_succeeded}</td>
                                    <td>{indexer.last_run_time}</td>
                                    <td>{indexer.index_name}</td>
                                    <td>{indexer.total_documents}</td>
                                    <td>
                                        <button className={styles.uploadButton} onClick={() => handleSpecificUpload(indexer)}>
                                            <FaFileUpload /> Upload Data
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                <button className={styles.uploadNewDataButton} onClick={handleGlobalUpload}>
                    <FaFileUpload /> Upload New Data
                </button>
            </div>

            {/* Modal for uploading data */}
            {showModal && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent} style={{ maxHeight: "80vh", maxWidth: "600px", overflowY: "auto", textAlign: "left" }}>
                        <button className={styles.closeModalButton} onClick={closeModal}>
                            ✖
                        </button>
                        <h2>{modalDataSource ? `Upload Data to ${modalDataSource.data_source_name}` : "Upload New Data"}</h2>
                        <div className={styles.uploadSection}>
                            {modalDataSource ? (
                                <div className={styles.dataSourceSection}>{renderDataSourceForm(modalDataSource.data_source_name)}</div>
                            ) : (
                                <>
                                    <h3>Select Data Source</h3>
                                    <select
                                        value={selectedDataSourceInModal}
                                        onChange={e => setSelectedDataSourceInModal(e.target.value)}
                                        className={styles.dropdown}
                                    >
                                        <option value="">Select Data Source</option>
                                        <option value="Local">Upload from Computer</option> {/* New option for Local upload */}
                                        <option value="Request Admin">Request Admin</option>
                                        <option value="AWS">AWS</option>
                                        <option value="Azure">Azure</option>
                                        <option value="Google Storage">Google Storage</option>
                                        <option value="SharePoint">SharePoint</option>
                                        <option value="Salesforce">Salesforce</option>
                                    </select>
                                    <div className={styles.dataSourceSection}>{renderDataSourceForm(selectedDataSourceInModal)}</div>
                                </>
                            )}
                            <div className={styles.chunkingStrategySection}>
                                <h3>Select Chunking Strategy</h3>
                                <select
                                    value={selectedChunkingStrategy}
                                    onChange={e => setSelectedChunkingStrategy(e.target.value)}
                                    className={styles.dropdown}
                                >
                                    <option value="onePage">One Page Chunking</option>
                                    <option value="overlapping">Overlapping Chunking</option>
                                    <option value="parentChild">Parent/Child Chunking</option>
                                </select>
                            </div>
                            <div className={styles.buttonSection}>
                                <button className={styles.submitButton} onClick={handleSubmit} disabled={!modalDataSource && !selectedDataSourceInModal}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DataUpload;
