import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import styles from "./SignIn.module.css";

const SignIn: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [organization, setOrganization] = useState(""); // State for organization
    const [errorMessage, setErrorMessage] = useState("");
    const [popupVisible, setPopupVisible] = useState(false); // Popup for active session
    const [popupDetails, setPopupDetails] = useState({
        message: "",
        currentDevice: "",
        action: ""
    }); // Popup details from backend response

    const [isForgotPasswordPopupVisible, setForgotPasswordPopupVisible] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [forgotPasswordMessage, setForgotPasswordMessage] = useState("");
    const [forgotPasswordStatus, setForgotPasswordStatus] = useState<"success" | "failure" | null>(null);

    const [otpPopupVisible, setOtpPopupVisible] = useState(false); // Popup for OTP input
    const [successPopupVisible, setSuccessPopupVisible] = useState(false); // Popup for success message
    const [otp, setOtp] = useState("");
    const [isResendingOtp, setIsResendingOtp] = useState(false);
    const [isSendingOtp, setIsSendingOtp] = useState(false); // State to track OTP sending process
    const [otpStatus, setOtpStatus] = useState<"success" | "failure" | null>(null); // Track OTP status for tick/cross

    const [retryLogin, setRetryLogin] = useState(false); // Retry login after logout

    const handleEmailLogin = async () => {
        if (!email || !password) {
            setErrorMessage("Please enter both email and password.");
            return;
        }

        try {
            const response = await axios.post(`/api/signin`, { email, password, organization });

            if (response.status === 200 && response.data?.token) {
                const token = response.data.token;

                localStorage.setItem("authToken", token);
                localStorage.setItem("userEmail", email);
                localStorage.setItem("deviceId", response.data.device_id);

                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

                navigate("/landing");
            } else if (response.status === 201) {
                // Handle concurrent session detected
                setPopupDetails({
                    message: response.data.message,
                    currentDevice: response.data.current_device_type,
                    action: response.data.action
                });
                setPopupVisible(true);
            } else {
                setErrorMessage(response.data?.error || "Login failed. Please try again.");
            }
        } catch (error: any) {
            console.error("Error during login:", error);

            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setErrorMessage("Invalid login for email and organization. Please provide email and password.");
                        break;
                    case 401:
                        setErrorMessage("Invalid password for email and organization.");
                        break;
                    case 402:
                        setErrorMessage("Your account is not subscribed. Please subscribe to access the application.");
                        break;
                    case 403:
                        setErrorMessage("Your subscription has expired. Please renew to continue.");
                        break;
                    default:
                        setErrorMessage("An unexpected error occurred during login. Please try again.");
                }
            } else {
                setErrorMessage("An unexpected error occurred. Please try again.");
            }
        }
    };

    const handleLogoutOtherDevice = async () => {
        try {
            const response = await axios.post(`/api/logout`, { email, organization });

            const newToken = response.data?.token;

            if (newToken) {
                // Update the token and revalidate session
                localStorage.setItem("authToken", newToken);
                axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
                setPopupVisible(false);

                // Retry the login process
                setRetryLogin(true);
            } else {
                throw new Error("Failed to fetch new token.");
            }
        } catch (error: any) {
            console.error("Error during device logout:", error);
            setErrorMessage("Failed to log out other device. Please try again.");
        }
    };

    const openOtpPopup = async () => {
        try {
            setOtpPopupVisible(true); // Show OTP input popup
            setIsSendingOtp(true); // Show loading indicator
            setOtpStatus(null); // Reset status

            // Send OTP to email
            await axios.post(`/send-otp`, { email });

            setOtpStatus("success"); // Set success status
        } catch (error: any) {
            console.error("Error sending OTP:", error);
            setErrorMessage("Failed to send OTP. Please try again.");
            setOtpStatus("failure"); // Set failure status
        } finally {
            setIsSendingOtp(false); // Stop loading indicator
        }
    };

    const handleOtpSubmit = async () => {
        if (!otp) {
            setErrorMessage("Please enter the OTP.");
            return;
        }

        try {
            const response = await axios.post(`/validate-otp`, { email, otp });

            if (response.status === 200) {
                // Show success message popup
                setOtpPopupVisible(false);
                setSuccessPopupVisible(true);
                setRetryLogin(false); // Trigger login process
            } else {
                setErrorMessage("Invalid OTP. Please try again.");
            }
        } catch (error: any) {
            console.error("Error validating OTP:", error);
            setErrorMessage("Failed to validate OTP. Please try again.");
        }
    };

    const handleResendOtp = async () => {
        try {
            setIsResendingOtp(true);
            await axios.post(`/send-otp`, { email });
            setIsResendingOtp(false);
            alert("OTP resent successfully. Check your email.");
        } catch (error: any) {
            console.error("Error resending OTP:", error);
            setIsResendingOtp(false);
            setErrorMessage("Failed to resend OTP. Please try again.");
        }
    };

    const retryLoginIfNeeded = () => {
        if (retryLogin) {
            setRetryLogin(false);
            handleEmailLogin();
        }
    };

    useEffect(() => {
        retryLoginIfNeeded();
    }, [retryLogin]);

    const validateEmail = (email: string) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const openForgotPasswordPopup = () => {
        setForgotPasswordPopupVisible(true);
        setForgotPasswordStatus(null);
        setForgotPasswordMessage("");
    };

    const closeForgotPasswordPopup = () => {
        setForgotPasswordPopupVisible(false);
        setForgotPasswordStatus(null);
        setIsSendingEmail(false);
    };

    const handleForgotPassword = async () => {
        if (!email) {
            setForgotPasswordMessage("Please enter your email address.");
            setForgotPasswordStatus("failure"); // Indicate failure
            return;
        }

        setIsSendingEmail(true);
        setForgotPasswordMessage("");

        try {
            const response = await axios.post(`/api/forgot-password`, { email });

            if (response.status === 200) {
                setForgotPasswordStatus("success"); // Indicate success
                setForgotPasswordMessage("Password reset email sent successfully. Please check your inbox.");
            } else {
                throw new Error("Failed to send reset email.");
            }
        } catch (error: any) {
            console.error("Error sending password reset email:", error);
            setForgotPasswordStatus("failure"); // Indicate failure
            setForgotPasswordMessage("Failed to send password reset email. Please try again.");
        } finally {
            setIsSendingEmail(false); // Stop showing "Sending..." state
        }
    };

    return (
        <div className={styles["sign-in-page"]}>
            <div className={styles["sign-in-container"]}>
                <div className={styles["sign-in-header"]}>
                    <h2>Welcome to</h2>
                    <h1>Floating Sage</h1>
                    <p>Please sign in to continue</p>
                </div>

                <div className={styles["email-login"]}>
                    <input
                        type="email"
                        className={`${styles["input-field"]} ${styles["stylish-input"]}`}
                        placeholder="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        className={`${styles["input-field"]} ${styles["stylish-input"]}`}
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <input
                        type="text"
                        className={`${styles["input-field"]} ${styles["stylish-input"]}`}
                        placeholder="Organization"
                        value={organization} // New state for organization
                        onChange={e => setOrganization(e.target.value)} // Handle organization input
                    />
                    <button className={`${styles["email-login-button"]} ${styles["stylish-button"]}`} onClick={handleEmailLogin}>
                        Login
                    </button>
                    {/* Forgot Password Link */}
                    <div className={styles["forgot-password-container"]}>
                        <span className={styles["forgot-password-link"]} onClick={openForgotPasswordPopup}>
                            Forgot Password?
                        </span>
                    </div>
                </div>

                {errorMessage && <p className={styles["error-message"]}>{errorMessage}</p>}

                {popupVisible && (
                    <div className={styles["popup-container"]}>
                        <div className={styles["popup"]}>
                            <h3>Concurrent Session Detected</h3>
                            <p>{popupDetails.message}</p>
                            <button className={styles["email-login-button"]} onClick={openOtpPopup}>
                                Log out of {popupDetails.currentDevice}
                            </button>
                            <button className={styles["go-back-button"]} onClick={() => setPopupVisible(false)}>
                                Go Back
                            </button>
                        </div>
                    </div>
                )}

                {otpPopupVisible && (
                    <div className={styles["popup-container"]}>
                        <div className={styles["popup"]}>
                            <h3>Enter OTP</h3>
                            <div className={styles["status-container"]}>
                                {isSendingOtp ? (
                                    <div className={styles["loading-spinner"]}></div> // Show loading spinner
                                ) : otpStatus === "success" ? (
                                    <FaCheck className={`${styles["status-icon"]} ${styles["success"]}`} /> // Green tick
                                ) : otpStatus === "failure" ? (
                                    <FaTimes className={`${styles["status-icon"]} ${styles["failure"]}`} /> // Red cross
                                ) : null}
                            </div>
                            <input
                                type="text"
                                placeholder="Enter your 6-digit OTP"
                                value={otp}
                                onChange={e => setOtp(e.target.value)}
                                className={`${styles["input-field"]} ${styles["stylish-input"]}`}
                            />
                            <button className={styles["logout-button"]} onClick={handleOtpSubmit}>
                                Submit OTP
                            </button>
                            <button className={styles["go-back-button"]} onClick={handleResendOtp} disabled={isResendingOtp}>
                                Resend OTP
                            </button>
                            <button className={styles["go-back-button"]} onClick={() => setOtpPopupVisible(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                )}

                {successPopupVisible && (
                    <div className={styles["popup-container"]}>
                        <div className={styles["popup"]}>
                            <h3>Success</h3>
                            <p>You have successfully logged out from the other device. You can now proceed.</p>
                            <button className={styles["go-back-button"]} onClick={handleLogoutOtherDevice}>
                                OK
                            </button>
                        </div>
                    </div>
                )}

                {/* Forgot Password Popup */}
                {isForgotPasswordPopupVisible && (
                    <div className={styles["popup-container"]}>
                        <div className={styles["popup"]}>
                            <h3>Forgot Password</h3>
                            <p>Please enter your email address to receive a reset link.</p>
                            <input
                                type="email"
                                className={`${styles["input-field"]} ${styles["stylish-input"]}`}
                                placeholder="Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <div className={styles["status-container"]}>
                                {isSendingEmail ? (
                                    <div className={styles["loading-spinner"]}></div>
                                ) : forgotPasswordStatus === "success" ? (
                                    <FaCheck className={`${styles["status-icon"]} ${styles["success"]}`} /> // Green tick
                                ) : forgotPasswordStatus === "failure" ? (
                                    <FaTimes className={`${styles["status-icon"]} ${styles["failure"]}`} /> // Red cross
                                ) : null}
                            </div>
                            <p className={styles["status-message"]}>{forgotPasswordMessage}</p>
                            <button className={styles["logout-button"]} onClick={handleForgotPassword} disabled={isSendingEmail}>
                                {isSendingEmail ? "Sending..." : "Reset Password"} {/* Always display "Reset Password" */}
                            </button>
                            <button className={styles["go-back-button"]} onClick={closeForgotPasswordPopup}>
                                Close
                            </button>
                        </div>
                    </div>
                )}

                <p className={styles["register-text"]}>
                    Don't have an account?{" "}
                    <Link to="/register" className={styles["register-link"]}>
                        Register here
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default SignIn;
