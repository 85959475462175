import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./EmailVerified.module.css";

const EmailVerificationPage: React.FC = () => {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate("/api/signin");
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1>Email Verified Successfully</h1>
                <p>You can now log in to your account.</p>
                <button onClick={handleLoginClick} className={styles.loginButton}>
                    Login
                </button>
            </div>
        </div>
    );
};

export default EmailVerificationPage;
