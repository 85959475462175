import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LoggedOutPage.module.css";

const LoggedOutPage: React.FC = () => {
    const navigate = useNavigate();

    const handleLoginAgain = () => {
        navigate("/api/signin");
    };

    return (
        <div className={styles["logged-out-page"]}>
            <div className={styles["content-container"]}>
                <h1>Session Ended</h1>
                <p>We noticed that you logged in from another device. To ensure your account's security, we have logged you out from this session.</p>
                <p>If this wasn't you, we recommend updating your password immediately to secure your account.</p>
                <button className={styles["login-again-button"]} onClick={handleLoginAgain}>
                    Login Again to Continue
                </button>
            </div>
        </div>
    );
};

export default LoggedOutPage;
