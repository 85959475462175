import { useState, useEffect } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Layout.module.css";
import { Modal, IconButton } from "@fluentui/react";
import floatingsageLogo from "../../assets/High-Resolution-Color-Logo-on-Transparent-Background - cropped.png";

const Layout = () => {
    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false); // State to manage disclaimer modal
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > window.innerHeight); // State to track screen orientation
    const navigate = useNavigate();

    // Effect to handle screen orientation changes
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > window.innerHeight);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Logout handler
    const handleLogout = async () => {
        try {
            const email = localStorage.getItem("userEmail"); // Retrieve email from localStorage
            if (!email) {
                console.error("Email is missing. Cannot log out.");
                return;
            }

            // Call backend logout API
            const response = await axios.post(
                "/api/logout",
                { email },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`
                    }
                }
            );

            if (response.status === 200) {
                localStorage.clear(); // Clear session details
                navigate("/"); // Redirect to sign-in page
            }
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    // Toggle function for disclaimer modal
    const toggleDisclaimer = () => {
        setIsDisclaimerOpen(!isDisclaimerOpen);
    };

    return (
        <div className={`${styles.layout} ${isWideScreen ? styles.verticalFit : styles.horizontalFit}`}>
            <header className={styles.header}>
                <div className={styles.headerContainer}>
                    <Link reloadDocument to="/" className={styles.headerTitleContainer}>
                        <img src={floatingsageLogo} alt="Floating Sage logo" className={styles.headerLogo} />
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li>
                                <IconButton
                                    iconProps={{ iconName: "Info" }}
                                    title="Disclaimer"
                                    ariaLabel="Disclaimer"
                                    className={styles.disclaimerButton}
                                    onClick={toggleDisclaimer}
                                />
                            </li>
                            <li className={styles.headerNavItem}>
                                <button onClick={handleLogout} className={styles.button}>
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            <main className={styles.mainContent}>
                <Outlet />
            </main>

            {/* Disclaimer Modal */}
            <Modal
                isOpen={isDisclaimerOpen}
                onDismiss={toggleDisclaimer}
                isBlocking={false}
                containerClassName={styles.modalContainer}
            >
                <div className={styles.modalHeader}>
                    <h2>Disclaimer</h2>
                    <IconButton iconProps={{ iconName: "Cancel" }} onClick={toggleDisclaimer} />
                </div>
                <div className={styles.modalContent}>
                    <b>DISCLAIMER:</b>
                    <br />
                    1. AI can make mistakes. Check important info.<br />
                    2. All user inputs and bot responses are logged{" "}
                    <Link to="/page-not-found" className={styles.link}>
                        Link to Acknowledgement.
                    </Link>
                </div>
            </Modal>
        </div>
    );
};

export default Layout;
