import { ChatRequest, ChatResponse, SearchSettings, UserProfile, ratingRequest, ratingResponse } from "./models";
import axios from "axios";

export class ChatResponseError extends Error {
    public retryable: boolean;

    constructor(message: string, retryable: boolean) {
        super(message);
        this.retryable = retryable;
    }
}

export class RatingResponseError extends Error {
    public retryable: boolean;

    constructor(message: string, retryable: boolean) {
        super(message);
        this.retryable = retryable;
    }
}

export async function chatApi(options: ChatRequest): Promise<ChatResponse> {
    try {
        const token = localStorage.getItem("authToken"); // Retrieve token from local storage
        const response = await axios.post<ChatResponse>(
            "/chat",
            {
                user_id: options.userID,
                conversation_id: options.conversationID,
                dialog_id: options.dialogID,
                dialog: options.dialog,
                overrides: {
                    semantic_ranker: options.overrides?.semanticRanker,
                    semantic_captions: options.overrides?.semanticCaptions,
                    top: options.overrides?.top,
                    temperature: options.overrides?.temperature,
                    suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                    classification_override: options.overrides?.classificationOverride,
                    vector_search: options.overrides?.vectorSearch,
                    pdfDataSources: options.overrides?.pdfDataSources
                }
            },
            {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token here
                }
            }
        );

        return response.data;
    } catch (error: any) {
        if (error.response?.status === 403) {
            localStorage.clear(); // Clear auth details if unauthorized
            window.location.href = "/"; // Redirect to login
        }
        throw new ChatResponseError(error.response?.data?.error ?? "An unknown error occurred.", error.response?.data?.show_retry ?? false);
    }
}

export async function getAllUsers(): Promise<UserProfile[]> {
    try {
        const token = localStorage.getItem("authToken"); // Retrieve token from local storage
        const response = await axios.get<UserProfile[]>("/user-profiles", {
            headers: {
                Authorization: `Bearer ${token}` // Include the token here
            }
        });

        return response.data;
    } catch (error: any) {
        if (error.response?.status === 403) {
            localStorage.clear(); // Clear auth details if unauthorized
            window.location.href = "/"; // Redirect to login
        }
        throw new Error(error.response?.data?.error || "Unauthorized access. Please log in.");
    }
}

export async function clearChatSession(userID: string, conversationID: string): Promise<void> {
    try {
        const token = localStorage.getItem("authToken"); // Retrieve token from local storage
        await axios.delete(`/chat-sessions/${userID}/${conversationID}`, {
            headers: {
                Authorization: `Bearer ${token}` // Include the token here
            }
        });
    } catch (error: any) {
        if (error.response?.status === 403) {
            localStorage.clear(); // Clear auth details if unauthorized
            window.location.href = "/"; // Redirect to login
        }
        throw new Error(`Received error response when attempting to clear chat session: ${error.response?.data || "Unknown error"}`);
    }
}

export async function getSearchSettings(): Promise<SearchSettings> {
    try {
        const token = localStorage.getItem("authToken"); // Retrieve token from local storage
        const response = await axios.get<SearchSettings>("/search-settings", {
            headers: {
                Authorization: `Bearer ${token}` // Include the token here
            }
        });
        return response.data;
    } catch (error: any) {
        if (error.response?.status === 403) {
            localStorage.clear(); // Clear auth details if unauthorized
            window.location.href = "/"; // Redirect to login
        }
        throw new Error("Received error response when fetching search settings.");
    }
}

export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}

export async function rating(options: ratingRequest): Promise<ratingResponse> {
    try {
        const token = localStorage.getItem("authToken"); // Retrieve token from local storage
        const response = await axios.post<ratingResponse>(
            "/rating",
            {
                user_id: options.user_id,
                conversation_id: options.conversation_id,
                index_no: options.index_no,
                rating: options.rating,
                feedback: options.feedback
            },
            {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token here
                }
            }
        );

        return response.data;
    } catch (error: any) {
        if (error.response?.status === 403) {
            localStorage.clear(); // Clear auth details if unauthorized
            window.location.href = "/"; // Redirect to login
        }
        throw new RatingResponseError(error.response?.data?.error ?? "An unknown error occurred.", error.response?.data?.show_retry ?? false);
    }
}
