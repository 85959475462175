import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./RegistrationPage.module.css";
import axios from "axios";

const RegistrationPage: React.FC = () => {
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        organization: "" // New field
    });
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadingMessage("Please wait... Checking registration status.");
        setErrorMessage(null);
        setSuccessMessage(null);

        if (formData.password !== formData.confirmPassword) {
            setErrorMessage("Passwords do not match.");
            setLoadingMessage(null);
            return;
        }

        try {
            // Check if user is already registered
            const checkResponse = await axios.post(`/api/register/check`, {
                email: formData.email,
                organization: formData.organization // Include organization in the check
            });
            if (checkResponse.data.isRegistered) {
                setLoadingMessage(null);
                setErrorMessage("User is already registered. Please log in.");
                return;
            }

            // If not registered, proceed to send registration data
            setLoadingMessage("Sending registration data. Please wait...");
            const registerResponse = await axios.post(`/api/register`, {
                username: formData.username,
                email: formData.email,
                password: formData.password,
                organization: formData.organization // Include organization in the registration
            });

            if (registerResponse.data && registerResponse.data.message) {
                setLoadingMessage(null);
                setSuccessMessage("Registration successful! Please check your email to verify your account.");
            } else {
                setLoadingMessage(null);
                setErrorMessage("An unexpected error occurred during registration. Please try again.");
            }
        } catch (error: any) {
            setLoadingMessage(null);

            if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(error.response.data.error);
            } else {
                setErrorMessage("An error occurred during registration. Please try again later.");
            }
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.glassCard}>
                <h2 className={styles.heading}>Create Your Account</h2>
                <p className={styles.subHeading}>Start your journey with us</p>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.inputGroup}>
                        <label htmlFor="username" className={styles.label}>
                            Username
                        </label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                placeholder="Enter your username"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="email" className={styles.label}>
                            Email
                        </label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="organization" className={styles.label}>
                            Organization
                        </label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="text"
                                id="organization"
                                name="organization"
                                value={formData.organization}
                                onChange={handleChange}
                                placeholder="Enter your organization"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="password" className={styles.label}>
                            Password
                        </label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="confirmPassword" className={styles.label}>
                            Confirm Password
                        </label>
                        <div className={styles.inputWrapper}>
                            <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                placeholder="Confirm your password"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <button type="submit" className={styles.submitButton}>
                        Register
                    </button>
                </form>
                {loadingMessage && (
                    <div className={styles.loadingMessage}>
                        <p>{loadingMessage}</p>
                    </div>
                )}
                {errorMessage && (
                    <div className={styles.errorMessage}>
                        <p>{errorMessage}</p>
                    </div>
                )}
                {successMessage && (
                    <div className={styles.successMessage}>
                        <p>{successMessage}</p>
                    </div>
                )}
                <p className={styles.loginText}>
                    Already have an account?{" "}
                    <Link to="/signin" className={styles.loginLink}>
                        Login here
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default RegistrationPage;
