import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import HomePage from "./pages/HomePage/HomePage";
import ContactPage from "./pages/ContactPage/ContactPage";
import UnderConstructionPage from "./pages/UnderConstructionPage/UnderConstructionPage";
import SignIn from "./pages/signin/SignIn";
import RegistrationPage from "./pages/registrationpage/RegistrationPage";
import Layout from "./pages/layout/Layout";
import PageContainer from "./pages/PageContainer";
import NoPage from "./pages/NoPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import DataUpload from "./pages/DataUpload/DataUpload";
import EmailVerificationRedirect from "./pages/EmailVerified/EmailVerified";
import LoggedOutPage from "./pages/LoggedOutPage/LoggedOutPage";
import ResetPassword from "./pages/ResetPassword/ResetPassword"; // Import ResetPassword page

import { PollingProvider } from "./pages/PollingContext/PollingContext"; // Import PollingProvider

initializeIcons();

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                {/* Root route for Sign-In page */}
                <Route path="/" element={<HomePage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/page-not-found" element={<UnderConstructionPage />} />
                <Route path="/api/signin" element={<SignIn />} />
                <Route path="/register" element={<RegistrationPage />} />
                <Route path="/email-verified" element={<EmailVerificationRedirect />} />
                <Route path="/loggedout" element={<LoggedOutPage />} />
                <Route path="/reset-password" element={<ResetPassword />} /> {/* ResetPassword Route */}
                {/* Routes with periodic polling */}
                <Route
                    path="/landing"
                    element={
                        <PollingProvider>
                            <LandingPage />
                        </PollingProvider>
                    }
                />
                <Route
                    path="/data-upload"
                    element={
                        <PollingProvider>
                            <DataUpload />
                        </PollingProvider>
                    }
                />
                <Route
                    path="/lander"
                    element={
                        <PollingProvider>
                            <Layout />
                        </PollingProvider>
                    }
                >
                    <Route index element={<PageContainer />} />
                </Route>
                {/* Fallback route in case of an unknown route */}
                <Route path="*" element={<NoPage />} />
            </Routes>
        </BrowserRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
