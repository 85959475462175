import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./ResetPassword.module.css";

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleResetPassword = async () => {
        if (!newPassword || !confirmPassword) {
            setErrorMessage("Please enter both fields.");
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        setIsSubmitting(true);
        setErrorMessage("");
        setSuccessMessage("");

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");

        if (!token) {
            setErrorMessage("Invalid or missing token. Please try again.");
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.post(`/api/reset-password`, {
                token,
                new_password: newPassword,
                confirm_password: confirmPassword
            });

            if (response.status === 200) {
                setSuccessMessage("Your password has been reset successfully. You can now log in.");
                setTimeout(() => navigate("/api/signin"), 3000);
            } else {
                throw new Error("Failed to reset password.");
            }
        } catch (error: any) {
            console.error("Error resetting password:", error);
            setErrorMessage("Failed to reset password. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className={styles["reset-password-page"]}>
            <div className={styles["reset-password-container"]}>
                <div className={styles["reset-password-header"]}>
                    <h1>Reset Your Password</h1>
                    <p>Please enter your new password below.</p>
                </div>

                <div className={styles["reset-password-form"]}>
                    <input
                        type="password"
                        className={`${styles["input-field"]} ${styles["stylish-input"]}`}
                        placeholder="New Password"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        className={`${styles["input-field"]} ${styles["stylish-input"]}`}
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <button className={`${styles["reset-password-button"]} ${styles["stylish-button"]}`} onClick={handleResetPassword} disabled={isSubmitting}>
                        {isSubmitting ? "Changing..." : "Change Password"}
                    </button>
                </div>

                {errorMessage && <p className={styles["error-message"]}>{errorMessage}</p>}
                {successMessage && <p className={styles["success-message"]}>{successMessage}</p>}
            </div>
        </div>
    );
};

export default ResetPassword;
