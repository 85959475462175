import React, { createContext, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PollingContext = createContext({});

export const PollingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();

    const validateToken = async () => {
        try {
            const token = localStorage.getItem("authToken");
            if (!token) throw new Error("Token is missing!");

            const response = await axios.get("/validate-token", {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.status !== 200) throw new Error("Invalid token!");
        } catch (error) {
            console.error("Token validation failed:", error);
            localStorage.clear(); // Clear session details
            navigate("/loggedout"); // Redirect to sign-in page
        }
    };

    useEffect(() => {
        const interval = setInterval(validateToken, 120000); // Poll every 60 seconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    return <PollingContext.Provider value={{}}>{children}</PollingContext.Provider>;
};

export const usePolling = () => useContext(PollingContext);
