import React, { useState } from "react";
import styles from "./ContactPage.module.css";
import axios from "axios";

const ContactPage: React.FC = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        countryCode: "+91",
        contactNumber: "",
        message: ""
    });
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    // const apiBaseUrl = process.env.REACT_APP_API_URL || "https://floatingsage-app-service.azurewebsites.net";
    // const apiBaseUrl = "https://floatingsage-in-backend-ffdwayahakcpbrbb.eastus2-01.azurewebsites.net";

    // const apiBaseUrl = "http://localhost:5000";
    // const apiBaseUrl = "https://floatingsage.com/upload";

    // const apiBaseUrl = process.env.REACT_APP_API_URL;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await axios.post(`/api/send-email`, formData);

            if (response.status === 200) {
                setIsPopupVisible(true);
            }
        } catch (error) {
            console.error("Error sending email:", error);
            alert("There was an error sending your request. Please try again later.");
        }
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.glassCard}>
                <h2 className={styles.heading}>Contact Us</h2>
                <p className={styles.subHeading}>We're here to help! Send us your queries</p>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.inputGroup}>
                        <label htmlFor="name" className={styles.label}>
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                            className={styles.input}
                        />
                    </div>

                    <div className={styles.inputGroup}>
                        <label htmlFor="email" className={styles.label}>
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            required
                            className={styles.input}
                        />
                    </div>

                    <div className={styles.inputGroup}>
                        <label htmlFor="contactNumber" className={styles.label}>
                            Contact Number
                        </label>
                        <div className={styles.contactNumberContainer}>
                            <input
                                type="text"
                                id="countryCode"
                                name="countryCode"
                                value={formData.countryCode}
                                onChange={handleChange}
                                placeholder="+91"
                                required
                                className={styles.countryCode}
                            />
                            <input
                                type="text"
                                id="contactNumber"
                                name="contactNumber"
                                value={formData.contactNumber}
                                onChange={handleChange}
                                placeholder="Enter your contact number"
                                required
                                className={styles.input}
                            />
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label htmlFor="message" className={styles.label}>
                            Message
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Enter your message"
                            rows={4}
                            required
                            className={styles.textarea}
                        />
                    </div>

                    <button type="submit" className={styles.submitButton}>
                        Request a Demo
                    </button>
                </form>

                {isPopupVisible && (
                    <div className={styles.popup}>
                        <div className={styles.popupContent}>
                            <p>Request Submitted. Our Team will get back within 1-2 Business Days. Thank you!</p>
                            <button onClick={handleClosePopup} className={styles.closePopupButton}>
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContactPage;
